import { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { gql, useMutation, useQuery } from '@apollo/client';
import useFeature from '../../utils/hooks/useFeature';
import useBinInventory from '../../utils/hooks/useBinInventory';
import useBinInventoryChartSeries from '../../utils/hooks/useBinInventoryChartSeries';
import { DeliveryStatus } from '../../utils/enums';
import CalibrationCardView from './CalibrationCardView';
import { InsertBinSetLevelMutation, UpdateBinSetLevelMutation, DeleteBinSetLevelMutation } from './queries';
import './CalibrationCard.scss';
import BinCheckDataPane from './BinCheckDataPane';
import BinSetCalibrationDataPane from './BinSetCalibrationDataPane';
import useUser from '../../utils/hooks/useUser';

const ROWS_PER_PAGE = 8;

function CalibrationCard({ binSetID = '', feedlines = [] }) {
  // TODO: Reorganize these hooks and derived values
  const { user } = useUser();
  const now = useMemo(() => dayjs(), []); // Memoized to avoid changing between renders.
  const { active: displayTruthData } = useFeature('DISPLAY_TRUTH_DATA');
  const { active: hideCoefficientChart } = useFeature('HIDE_COEFFICIENT_CHART');
  const { active: showBinLevelStoreTable } = useFeature('SHOW_BIN_LEVEL_SCORE_TABLE');
  const showCoefficientChart = !hideCoefficientChart;
  const from = dayjs().subtract(6, 'month').startOf('day');
  const to = dayjs().endOf('day');
  const [dateRange, setDateRange] = useState({ to, from });

  const [rowData, setRowData] = useState({});
  const [pageNum, setPageNum] = useState(0);
  const [selectedBinSetLevelId, setSelectedBinSetLevelId] = useState(null);
  const [isCreatingNewBinSetLevel, setIsCreatingNewBinSetLevel] = useState(false);
  const [isCreatingNewBinSetCalibration, setIsCreatingNewBinSetCalibration] = useState(false);
  const [selectedBinSetCalibrationId, setSelectedBinSetCalibrationId] = useState(null);
  const [insertBinSetLevel] = useMutation(InsertBinSetLevelMutation);
  const [updateBinSetLevel] = useMutation(UpdateBinSetLevelMutation);
  const [deleteBinSetLevel] = useMutation(DeleteBinSetLevelMutation);
  const { fetchInventoryData, getBinLevelPredictionData } = useBinInventory();
  const { chart } = fetchInventoryData(binSetID, now, pageNum, ROWS_PER_PAGE);
  const { loading: inventoryChartLoading, error: inventoryChartError, data: inventoryChartData } = chart;
  const { buildCalculatedBinSetLevelSeries, buildHistoricBinSetLevelSeries, buildTruthDataSeries } =
    useBinInventoryChartSeries();

  const { loading: loadingBinSetCalibrations, data: binSetCalibrationData } = useQuery(
    gql`
      query CalibrationCard_GetBinSetCalibrations($bin_set_id: uuid!) {
        bin_set_calibration(where: { bin_set_id: { _eq: $bin_set_id }, deleted_at: { _is_null: true } }) {
          id
          started_at
          ended_at
          provided_mass_in_grams
          displayed_calibration_step
        }
      }
    `,
    { variables: { bin_set_id: binSetID } },
  );
  const binSetCalibrations = binSetCalibrationData?.bin_set_calibration;

  useEffect(() => {
    setDateRange({
      ...dateRange,
      from: dayjs(inventoryChartData?.earliestBinSetLevel?.valid_at * 1000 || dateRange.from),
    });
  }, [inventoryChartData?.earliestBinSetLevel?.valid_at]);

  // Categorize graphql response data into deliveries and orders arrays.
  // Deliveries and orders both have the shape: { timestamp: seconds (number), value: grams (number) }
  const deliveries = [];
  const orders = [];
  inventoryChartData?.binSetData?.bin_set?.[0].bins?.forEach((bin) => {
    bin?.deliveries?.forEach((delivery) => {
      const value = delivery.weight_in_grams;
      if (DeliveryStatus.Delivered === delivery.status) {
        const timestamp = delivery.delivered_at;
        deliveries.push({ timestamp, value });
      } else if (DeliveryStatus.Ordered === delivery.status) {
        const timestamp = delivery.ordered_at;
        orders.push({ timestamp, value });
      }
    });
  });

  const chartSeries = [
    buildHistoricBinSetLevelSeries(rowData?.historicLiveView),
    buildCalculatedBinSetLevelSeries(rowData?.calibratedView),
  ];

  if (displayTruthData) {
    chartSeries.push(buildTruthDataSeries(inventoryChartData?.binSetData?.known_bin_level));
  }

  const binLevelResults = useMemo(() => {
    const levels = inventoryChartData?.all_bin_set_levels?.filter((l) => l.purpose);
    let historicLiveViewReversed = null;
    let calibratedViewReversed = null;

    if (rowData?.historicLiveView) historicLiveViewReversed = [...rowData.historicLiveView].reverse();
    if (rowData?.calibratedView) calibratedViewReversed = [...rowData.calibratedView].reverse();

    const output = levels?.map((l, index) => {
      let livePoint = null;
      let recalculatedPoint = null;
      const sampleTime = l.valid_at;
      if (historicLiveViewReversed) {
        livePoint = historicLiveViewReversed.find((x) => x.rawOccurredAt < sampleTime);
      }
      if (calibratedViewReversed) {
        recalculatedPoint = calibratedViewReversed.find((x) => x.rawOccurredAt < sampleTime);
      }

      let previousLevel = null;
      let totalFeedMeasured = null;
      if (index > 0) {
        previousLevel = levels[index - 1];
      }

      if (previousLevel && deliveries) {
        totalFeedMeasured =
          previousLevel.level_in_grams -
          l.level_in_grams +
          deliveries
            .filter((d) => {
              return d.timestamp > previousLevel.valid_at && d.timestamp < l.valid_at;
            })
            .reduce((sum, d) => sum + d.value, 0);
      }
      return {
        time: l.valid_at,
        target: l.level_in_grams,
        liveValue: livePoint?.value,
        liveTime: livePoint?.valid_at,
        recalculatedValue: recalculatedPoint?.value,
        recalculatedTime: recalculatedPoint?.valid_at,
        totalFeedMeasured,
        purpose: l.purpose,
      };
    });
    return output;
  }, [inventoryChartData?.all_bin_set_levels, rowData?.historicLiveView, rowData?.calibratedView, deliveries]);

  useEffect(() => {
    const getRowData = async () => {
      if (!inventoryChartData?.binSetData?.bin_set?.[0]) {
        return;
      }

      let row;
      try {
        // Fetch the data from hasura and create a rough representation of the row as an object
        row = await getBinLevelPredictionData(
          inventoryChartData?.binSetData.bin_set?.[0],
          inventoryChartData?.earliestBinSetLevel,
          now,
          inventoryChartData?.binSetLevelsForInventory,
        );

        setRowData({
          name: row?.name,
          barnName: inventoryChartData?.binSetData.bin_set?.[0]?.farm?.name || 'Unknown',
          bins: inventoryChartData?.binSetData.bin_set?.[0].bins.map((b) => b.name).join(', '),
          totalCapacity: row?.totalCapacity,
          currentBinLevel: row?.currentBinLevel,
          minimumSafe: row?.minimumSafe,
          earliestDelivery: row?.earliestDelivery,
          debug: row?.debug,
          calibratedView: row?.calibratedViewSeries,
          historicLiveView: row?.historicLiveViewSeries,
          coefficientTimeSeries: row?.coefficientTimeSeries,
        });
      } catch (e) {
        console.error(e);
      }
    };

    getRowData();
  }, [inventoryChartData?.binSetData]);

  const getRefetchQueries = (refetchChart) => {
    let refetchQueries = ['BinCalibration_BinChecks'];

    if (refetchChart) {
      refetchQueries = ['BinCalibration_BinSetLevelQuery', 'BinCalibration_BinSetDataQuery', ...refetchQueries];
    }

    return refetchQueries;
  };

  const onInsertBinSetLevel = (
    occurredAtInSeconds,
    levelInGrams,
    binSetID,
    source,
    method,
    purpose,
    expectedDeviationInGrams,
    notes,
    refetchChart,
  ) => {
    insertBinSetLevel({
      variables: {
        object: {
          valid_at: occurredAtInSeconds,
          level_in_grams: levelInGrams,
          bin_set_id: binSetID,
          source,
          method,
          purpose,
          expected_deviation_in_grams: expectedDeviationInGrams,
          comment: notes,
        },
      },
      refetchQueries: getRefetchQueries(refetchChart),
    });
  };

  const onUpdateBinSetLevel = (
    binSetLevelID,
    occurredAtInSeconds,
    levelInGrams,
    binSetID,
    source,
    method,
    purpose,
    expectedDeviationInGrams,
    notes,
  ) => {
    updateBinSetLevel({
      variables: {
        id: binSetLevelID,
        valid_at: occurredAtInSeconds,
        level_in_grams: levelInGrams,
        bin_set_id: binSetID,
        source,
        method,
        purpose,
        expected_deviation_in_grams: expectedDeviationInGrams,
        comment: notes,
      },
      refetchQueries: ['BinCalibration_BinChecks'],
    });
  };

  const onDeleteBinSetLevel = (binSetLevelID) => {
    deleteBinSetLevel({
      variables: {
        id: binSetLevelID,
        deleted_at: dayjs().unix(),
      },
      refetchQueries: getRefetchQueries(true),
    });
  };

  const onClickNext = () => {
    setPageNum(pageNum + 1);
  };

  const onClickPrevious = () => {
    setPageNum(pageNum - 1);
  };

  let dataPaneChildren = null;

  if (selectedBinSetLevelId || isCreatingNewBinSetLevel) {
    dataPaneChildren = (
      <>
        <h4>Bin Check</h4>
        <hr />
        <BinCheckDataPane
          binSetLevelId={selectedBinSetLevelId}
          isEditing={!isCreatingNewBinSetLevel}
          binSetId={binSetID}
          numBins={inventoryChartData?.binSetData?.bin_set?.[0]?.bins?.length}
          onClose={() => {
            setSelectedBinSetLevelId(null);
            setIsCreatingNewBinSetLevel(false);
          }}
        />
      </>
    );
  } else if (selectedBinSetCalibrationId || isCreatingNewBinSetCalibration) {
    dataPaneChildren = (
      <>
        <h4>Precision Calibration</h4>
        <hr />
        <BinSetCalibrationDataPane
          binSetCalibrationId={selectedBinSetCalibrationId}
          isEditing={!isCreatingNewBinSetCalibration}
          binSetId={binSetID}
          numBins={inventoryChartData?.binSetData?.bin_set?.[0]?.bins?.length}
          onClose={() => {
            setSelectedBinSetCalibrationId(null);
            setIsCreatingNewBinSetCalibration(false);
          }}
        />
      </>
    );
  }

  return (
    <CalibrationCardView
      chartLoading={inventoryChartLoading && loadingBinSetCalibrations}
      minDate={dayjs(inventoryChartData?.earliestBinSetLevel?.valid_at * 1000)}
      maxDate={dayjs().add(1, 'day').endOf('day')}
      data={rowData}
      chartSeries={chartSeries}
      pageNum={pageNum}
      maxRows={ROWS_PER_PAGE}
      onInsertBinSetLevel={onInsertBinSetLevel}
      onUpdateBinSetLevel={onUpdateBinSetLevel}
      onDeleteBinSetLevel={onDeleteBinSetLevel}
      onClickNext={onClickNext}
      onClickPrevious={onClickPrevious}
      feedlines={feedlines}
      binSetLevels={inventoryChartData?.all_bin_set_levels}
      binSetCalibrations={binSetCalibrations}
      deliveries={[]}
      dataPaneChildren={dataPaneChildren}
      onBinSetLevelClicked={(id) => {
        setIsCreatingNewBinSetLevel(false);
        if (id === selectedBinSetLevelId) {
          setSelectedBinSetLevelId(null);
        } else {
          setSelectedBinSetLevelId(id);
        }

        setSelectedBinSetCalibrationId(null);
      }}
      onBinSetCalibrationClicked={(id) => {
        if (id === selectedBinSetCalibrationId) {
          setSelectedBinSetCalibrationId(null);
        } else {
          setSelectedBinSetCalibrationId(id);
        }
        setSelectedBinSetLevelId(null);
      }}
      selectedBinSetLevel={selectedBinSetLevelId}
      selectedBinSetCalibration={selectedBinSetCalibrationId}
      onClickNewBinSetLevel={() => setIsCreatingNewBinSetLevel(true)}
      onClickNewBinSetCalibration={() => {
        setIsCreatingNewBinSetCalibration(true);
      }}
      dateRange={dateRange}
      setDateRange={setDateRange}
      error={inventoryChartError}
      displayCoefficientChart={user.isStaff && showCoefficientChart}
      binLevelResults={binLevelResults}
      displayBinLevelTable={user.isStaff && showBinLevelStoreTable}
    />
  );
}

CalibrationCard.propTypes = {
  binSetID: PropTypes.string,
  mode: PropTypes.string,
  feedlines: PropTypes.array,
};

export default CalibrationCard;
