import { useEffect } from 'react';
import { useAtom } from 'jotai';

import useUser from '../../utils/hooks/useUser';
import LoadingSkeleton from '../../atoms/LoadingSkeleton';
import { featureFlagsAtom, featureFlagAtomsAtom } from '../../utils/jotaiAtoms';
import FeatureFlagRow from './FeatureFlagRow';

import './FeatureFlagManager.scss';

// Start Feature Flag Fake
// Faking the graphql query if we move this to the DB
const fakeStaffResponse = {
  loading: false,
  data: [
    { name: 'DEVELOPER_MODE', active: false },
    { name: 'SHOW_ALL_FAULTS', active: false },
    { name: 'INVENTORY_DEBUG_MODE', active: false },
    { name: 'CENSOR_SENSITIVE_FIELDS', active: false },
    { name: 'DISPLAY_TRUTH_DATA', active: false },
    { name: 'SHOW_FRAME_DATA_COVERAGE', active: false },
    { name: 'DISPLAY_COPY_ID_BUTTONS', active: true },
    { name: 'HIDE_COEFFICIENT_CHART', active: false },
    { name: 'SHOW_BIN_LEVEL_SCORE_TABLE', active: false },
  ],
  error: null,
};

export const fakeUserResponse = {
  loading: false,
  data: [{ name: 'CENSOR_SENSITIVE_FIELDS', active: false }],
  error: null,
};

export function useFakeQuery(isStaff, options) {
  if (options?.skip) {
    return {
      loading: false,
      data: null,
      error: null,
    };
  }
  return isStaff ? fakeStaffResponse : fakeUserResponse;
}
// end Feature Flag Fake
/***
 * ? NEEDS REVIEW ?
 */
export default function FeatureFlagManager() {
  const { user } = useUser();
  const { loading, data, error } = useFakeQuery(user?.isStaff);
  const [featureFlags, setFeatureFlags] = useAtom(featureFlagsAtom); // the array is an atom
  const [features] = useAtom(featureFlagAtomsAtom); // each item in features is an atom

  if (error) {
    return <div> An Error has Occurred in the Feature Flag Manager </div>;
  }

  useEffect(() => {
    // If the lengths are the same we're going to assume with our fakeResponse that they're the same
    // No need to re-fetch and cause an infinite re-render loop
    // Replace with async code if moved to the DB though!
    if (featureFlags?.length !== data?.length) {
      setFeatureFlags(data);
    }
  }, [data]);

  const onClickClearFlags = () => setFeatureFlags(featureFlags.map((item) => ({ ...item, active: false })));

  return (
    <div className="FeatureFlagManager">
      <div className="FeatureFlagManager-header">
        <button
          className={`FeatureFlagManager-clearFlagsButton ${
            loading && 'FeatureFlagManager-clearFlagsButton--disabled'
          }`}
          onClick={onClickClearFlags}
        >
          Deactivate All Feature Flags
        </button>
        <div className="FeatureFlagManager-note">Note: Flags are stored in your browser</div>
      </div>

      {
        // If we're loading show an even number of loading skeletons
        loading
          ? Array(4)
              .fill(0)
              .map((_, index) => <LoadingSkeleton key={index} className="FeatureFlagManager-skeleton" />)
          : features.map((featureAtom) => <FeatureFlagRow featureAtom={featureAtom} key={`${featureAtom}`} />)
      }
    </div>
  );
}
