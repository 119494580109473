import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './ToggleSwitch.scss';

function ToggleSwitch({
  className = '',
  toggleClassName = '',
  textClassName = '',
  backgroundClassName = '',
  textLeft = '',
  textRight = '',
  tabIndex = 0,
  isActive = false,
  disabled = false,
  onToggle = () => {},
}) {
  const [active, setActive] = useState(isActive);

  const onToggleClick = () => {
    if (!disabled) {
      setActive(!active);
      onToggle(!active);
    }
  };

  const onKeyboardToggle = (e) => {
    if (e.key !== 'Enter' && e.code !== 'Space') {
      return;
    }
    if (!disabled) {
      e.preventDefault();
      onToggleClick();
    }
  };

  useEffect(() => {
    // Allows Toggle Switch to be controlled by external sources
    setActive(isActive);
  }, [isActive]);

  return (
    <div className={`ToggleSwitch ${className}`}>
      {textLeft && <span className={`ToggleSwitch-text ToggleSwitch-textLeft ${textClassName}`}>{textLeft}</span>}
      <div
        className={`ToggleSwitch-background ${active ? 'ToggleSwitch--active' : ''} ${
          disabled ? 'disabled' : ''
        } ${backgroundClassName}`}
        tabIndex={tabIndex}
        onKeyDown={onKeyboardToggle}
        onClick={onToggleClick}
      >
        <div className={`ToggleSwitch-toggle ${toggleClassName}`} />
      </div>
      {textRight && <span className={`ToggleSwitch-text ToggleSwitch-textRight ${textClassName}`}>{textRight}</span>}
    </div>
  );
}

ToggleSwitch.propTypes = {
  className: PropTypes.string,
  toggleClassName: PropTypes.string,
  textClassName: PropTypes.string,
  backgroundClassName: PropTypes.string,
  tabIndex: PropTypes.number,
  isActive: PropTypes.bool,
  disabled: PropTypes.bool,
  onToggle: PropTypes.func,
  textLeft: PropTypes.string,
  textRight: PropTypes.string,
};

export default ToggleSwitch;
