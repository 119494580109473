import React, { useContext } from 'react';
import { useParams, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { gql, useQuery } from '@apollo/client';
import { convertGramsToSmallUnits, weightSmallUnitLabel } from '../../utils/unitConversion';
import WebAppContext from '../../utils/webAppContext';
import dayjs from 'dayjs';
import {
  DATE_FORMAT_MONTH_DAY_YEAR,
  DATE_TIME_FORMAT_MONTH_DAY_HOUR_MINUTE,
  TIME_FORMAT_HOURS_MINUTES,
  secondsToDuration,
} from '../../utils/dates';
import Card from '../../atoms/Card';
import SensorChart from '../../organisms/DeviceCard/SensorDataChart';

import './FeedFramePage.scss';
import CopyButton from '../../atoms/CopyButton';

const SENSOR_DATA_MARGIN_SECONDS = 5 * 60;

const FEED_FRAME_GQL = gql`
  query FeedFramePage_frame($id: uuid!) {
    feed_frame_by_pk(id: $id) {
      id
      algorithm_version
      created_at
      deleted_at
      duration
      started_at
      ended_at
      feed_frame_truths(where: { deleted_at: { _is_null: true } }, order_by: { created_at: desc }) {
        truth_mass_in_grams
      }
      feed_frame_analyses(order_by: { created_at: desc }) {
        id
        algorithm_version
        created_at
        latest_estimated_mass_moved_in_grams
        mass_moved_in_grams
        extra_data
        feed_frame_corrected_analyses(order_by: { created_at: desc }) {
          id
          created_at
          deleted_at
          corrected_mass_moved_in_grams
          feed_line_coefficients {
            id
            coefficients
            started_at
            ended_at
            live_ended_at
            is_published
            created_at
            deleted_at
          }
        }
      }
      device {
        id
        hw_version
        serial
      }
      feed_line {
        name
        farm {
          name
        }
        bin_set {
          bins {
            name
          }
        }
      }
    }
  }
`;

function FeedFramePage() {
  const { id } = useParams();
  const { isMetric } = useContext(WebAppContext);

  const { loading, data, error } = useQuery(FEED_FRAME_GQL, {
    variables: {
      id: id,
    },
  });

  if (error) {
    return (
      <>
        <h1>Feed Frame Page </h1>
        <h2>Error</h2>
        <code>{JSON.stringify(error, null, '\t')}</code>
      </>
    );
  }

  if (loading) {
    return (
      <>
        <h1>Feed Frame Page </h1>
        <h2>Loading</h2>
      </>
    );
  }

  const ff = data?.feed_frame_by_pk;
  const durationText = secondsToDuration(ff?.ended_at - ff?.started_at);
  const durationMinutes = (ff?.ended_at - ff?.started_at) / 60.0;

  return (
    <div className="FeedFramePage">
      <h1>
        Feed Frame @ {ff?.feed_line?.farm?.name} : {ff?.feed_line?.name}
      </h1>
      <b>ID:</b>
      {ff?.id}
      <CopyButton data={ff?.id} />
      <br />
      <b>Latest Error:</b>{' '}
      {ff?.feed_frame_truths[0]?.truth_mass_in_grams == 0
        ? 'div By 0'
        : (
            (ff?.feed_frame_analyses[0]?.latest_estimated_mass_moved_in_grams /
              ff?.feed_frame_truths[0]?.truth_mass_in_grams -
              1) *
            100
          ).toFixed(2)}
      %
      <br />
      <b>Latest Mass Moved:</b>{' '}
      {convertGramsToSmallUnits(isMetric, ff?.feed_frame_analyses[0]?.latest_estimated_mass_moved_in_grams)}{' '}
      {weightSmallUnitLabel(isMetric)}
      <br />
      <b>Latest Flow Rate:</b>{' '}
      {convertGramsToSmallUnits(
        isMetric,
        ff?.feed_frame_analyses[0]?.latest_estimated_mass_moved_in_grams / durationMinutes,
      )}{' '}
      {weightSmallUnitLabel(isMetric)}/min
      <br />
      <b>Truth Mass:</b> {convertGramsToSmallUnits(isMetric, ff?.feed_frame_truths[0]?.truth_mass_in_grams)}{' '}
      {weightSmallUnitLabel(isMetric)}
      <br />
      <b>Truth Flow Rate:</b>{' '}
      {convertGramsToSmallUnits(isMetric, ff?.feed_frame_truths[0]?.truth_mass_in_grams / durationMinutes)}{' '}
      {weightSmallUnitLabel(isMetric)}/min
      <br />
      <br />
      <b>Duration:</b> {durationText}
      <br />
      <b>Started At:</b>{' '}
      {dayjs.tz(ff?.started_at * 1000).format(DATE_FORMAT_MONTH_DAY_YEAR + ' ' + TIME_FORMAT_HOURS_MINUTES)}{' '}
      <CopyButton data={ff?.started_at} />
      <br />
      <b>Ended At:</b>{' '}
      {dayjs.tz(ff?.ended_at * 1000).format(DATE_FORMAT_MONTH_DAY_YEAR + ' ' + TIME_FORMAT_HOURS_MINUTES)}{' '}
      <CopyButton data={ff?.ended_at} />
      <br />
      <b>Created At:</b>{' '}
      {dayjs.tz(ff?.created_at * 1000).format(DATE_FORMAT_MONTH_DAY_YEAR + ' ' + TIME_FORMAT_HOURS_MINUTES)}{' '}
      <CopyButton data={ff?.created_at} />
      <br />
      <b>Deleted At :</b> {ff?.deleted_at || 'N/A'}
      <br />
      <br />
      <b>Device ID:</b> {ff?.device.id}
      <br />
      <b>Device Serial:</b>{' '}
      <Link to={`/devices?name=Default+List&sort=nameAsc&d=${ff?.device.serial}`}>{ff?.device.serial} 🔗</Link>
      <br />
      <b>Device HW:</b> {ff?.device.hw_version}
      <br />
      <b>Bin Names:</b> {ff?.feed_line?.bin_set?.bins.map((x) => x.name).join(',')}
      <br />
      <Link to={`/rawData/${ff?.device.id}?from=${ff?.started_at}&to=${ff?.ended_at}`}>📈 Full Screen Raw Data</Link>
      <SensorChart
        deviceId={ff?.device.id}
        from={dayjs.tz(1000 * (ff?.started_at - SENSOR_DATA_MARGIN_SECONDS))}
        to={dayjs.tz(1000 * (ff?.ended_at + SENSOR_DATA_MARGIN_SECONDS))}
      ></SensorChart>
      <h2>Analyses</h2>
      {ff?.feed_frame_analyses.map((ffa) => {
        return (
          <Card key={ffa.id}>
            <h4>Feed Frame Analysis:</h4>
            <b>Uncalibrated Mass Moved</b> {convertGramsToSmallUnits(isMetric, ffa.mass_moved_in_grams)}
            {weightSmallUnitLabel(isMetric)}
            <br />
            <b>Calibrated Mass Moved</b> {convertGramsToSmallUnits(isMetric, ffa.latest_estimated_mass_moved_in_grams)}
            {weightSmallUnitLabel(isMetric)}
            <br />
            <b>Algorithm Version (SHA)</b> {ffa.algorithm_version}
            <br />
            <b>CreatedAt</b> {dayjs.tz(ffa.created_at * 1000).format(DATE_FORMAT_MONTH_DAY_YEAR)}
            <br />
            <b>
              <u>Stats</u>
            </b>
            <br />
            <div className="FeedFramePage-stats">
              <b>Average Rotational Speed</b>{' '}
              <span>{Number(ffa.extra_data?.rot_speed_avg).toFixed(2).padStart(5, '0')}dHz</span>
              <b>Median Rotational Speed</b>{' '}
              <span>{Number(ffa.extra_data?.rot_speed_median).toFixed(2).padStart(5, '0')}dHz</span>
              <b>Average Feed Height</b>{' '}
              <span>{Number(ffa.extra_data?.feed_height_avg).toFixed(2).padStart(5, '0')}mm (note: with bonus)</span>
              <b>Median Feed Height</b>{' '}
              <span>{Number(ffa.extra_data?.feed_height_median).toFixed(2).padStart(5, '0')}mm (note: with bonus)</span>
              <b>Standard Deviation Feed Height</b>
              <span>
                {Number(ffa.extra_data?.feed_height_std_dev).toFixed(2).padStart(5, '0')}mm (note: with bonus)
              </span>
            </div>
            <b>Calibrations:</b>
            {ffa.feed_frame_corrected_analyses.map((ffca) => {
              return (
                <Card key={ffca.id}>
                  <h4>Corrected Feed Frame Analysis:</h4>
                  <b>ID: </b> {ffca.id}
                  <br />
                  <b>Created at: </b>
                  {dayjs.tz(ffca.created_at * 1000).format(DATE_TIME_FORMAT_MONTH_DAY_HOUR_MINUTE)}
                  <br />
                  <b>Deleted at: </b>
                  {ffca.deleted_at
                    ? dayjs.tz(ffca.deleted_at * 1000).format(DATE_TIME_FORMAT_MONTH_DAY_HOUR_MINUTE)
                    : 'Null'}
                  <br />
                  <b>Corrected Mass </b> {convertGramsToSmallUnits(isMetric, ffca.corrected_mass_moved_in_grams)}
                  {weightSmallUnitLabel(isMetric)}
                  <br />
                  <hr></hr>
                  <h4>Coefficient:</h4> {ffca.feed_line_coefficients.id}{' '}
                  <CopyButton data={ffca.feed_line_coefficients.id} />
                  <pre>{JSON.stringify(ffca.feed_line_coefficients.coefficients, null, 4)}</pre>
                  <br />
                  <b>Created at: </b>
                  {dayjs
                    .tz(ffca.feed_line_coefficients.created_at * 1000)
                    .format(DATE_TIME_FORMAT_MONTH_DAY_HOUR_MINUTE)}
                  <br />
                  <b>Deleted at: </b>
                  {ffca.feed_line_coefficients.deleted_at
                    ? dayjs
                        .tz(ffca.feed_line_coefficients.deleted_at * 1000)
                        .format(DATE_TIME_FORMAT_MONTH_DAY_HOUR_MINUTE)
                    : 'Null'}
                  <br />
                  <b>Applied at: Start - End - Live End</b>
                  <br></br>
                  {dayjs
                    .tz(ffca.feed_line_coefficients.started_at * 1000)
                    .format(DATE_TIME_FORMAT_MONTH_DAY_HOUR_MINUTE)}
                  {' - '}
                  {ffca.feed_line_coefficients.ended_at
                    ? dayjs
                        .tz(ffca.feed_line_coefficients.ended_at * 1000)
                        .format(DATE_TIME_FORMAT_MONTH_DAY_HOUR_MINUTE)
                    : 'Now'}
                  {' - '}
                  {ffca.feed_line_coefficients.ended_at
                    ? dayjs
                        .tz(ffca.feed_line_coefficients.live_ended_at * 1000)
                        .format(DATE_TIME_FORMAT_MONTH_DAY_HOUR_MINUTE)
                    : 'Now'}
                </Card>
              );
            })}
          </Card>
        );
      })}
      <pre>{JSON.stringify(data, null, 4)}</pre>
      <pre>{JSON.stringify(error, null, 4)}</pre>
    </div>
  );
}

FeedFramePage.propTypes = {
  testCase: PropTypes.object,
  testCaseMetaData: PropTypes.object,
  target_error_value: PropTypes.number,
};

export default FeedFramePage;
