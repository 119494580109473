import React, { useContext, useEffect, useMemo, useReducer } from 'react';
import PropTypes from 'prop-types';
import { gql, useQuery } from '@apollo/client';
import { useAtomValue } from 'jotai';
import { algorithmVersionAtom } from '../../utils/jotaiAtoms';
import useSearchParamsState from '../../utils/hooks/useSearchParamsState';
import Page from '../../atoms/Page';
import FeedFloTextInput from '../../atoms/FeedFloTextInput';
import TestCategory from './TestCategory';
import FeedFloDropDown from '../../atoms/FeedFloDropDown';
import ComboboxMulti from '../../atoms/ComboboxMulti';
import ToggleSwitch from '../../atoms/ToggleSwitch';
import './AccuracyScorePage.scss';
import Card from '../../atoms/Card';
import StatusBadge from '../../atoms/StatusBadge';
import ErrorChart from './ErrorChart';
import { convertGramsToLargeUnits, weightLargeUnitLabel } from '../../utils/unitConversion';
import WebAppContext from '../../utils/webAppContext';

const TestCaseFilterOption = Object.freeze({
  ALL: 'all',
  ACCEPTED: 'accepted',
  DISQUALIFIED: 'disqualified',
});

const TEST_CATEGORIES_LIST_GQL = gql`
  query TestCategoryQuery {
    test_category(where: { deleted_at: { _is_null: true } }) {
      id
      name
      selected
    }
  }
`;

function AccuracyScorePage({ titleSegments = [] }) {
  const algorithmVersion = useAtomValue(algorithmVersionAtom);
  const [sha, setSha] = useSearchParamsState('sha', algorithmVersion);
  const [testCaseFilter, setTestCaseFilter] = useSearchParamsState('filter', TestCaseFilterOption.ACCEPTED);
  const [selectedTestCategoryIds, setSelectedTestCategoryIds] = useSearchParamsState('c', []);
  const [uncalibrated, setUncalibrated] = useSearchParamsState('u', false);
  const [sortBy, setSort] = useSearchParamsState('s', 'time-asc');

  const { isMetric } = useContext(WebAppContext);
  const [testCaseScores, setTestCaseMetaData] = useReducer(
    (state, action) => {
      if (!action) return state;

      switch (action.type) {
        case 'updateMetaData':
          state.categories[action.id] = action.metaData;
          break;

        default:
          break;
      }

      let newMetaDataCache = {};
      selectedTestCategoryIds.forEach((id) => {
        if (state.categories[id]) newMetaDataCache = { ...newMetaDataCache, ...state.categories[id] };
      });
      state.metaData = newMetaDataCache;

      return { ...state };
    },
    { categories: {}, metaData: {} },
  );

  useEffect(() => {
    setTestCaseMetaData({ type: 'updateVisibility' });
  }, [selectedTestCategoryIds]);

  const { loading, data, error } = useQuery(TEST_CATEGORIES_LIST_GQL);

  useEffect(() => {
    // Adding as a use effect as if you go directly to this page the atom value isn't set yet
    if (algorithmVersion && !sha) {
      setSha(algorithmVersion);
    }
  }, [algorithmVersion]);

  const pageTitleSegments = useMemo(() => ['Accuracy Score Page', ...titleSegments], []);
  const testCaseFilterOptionList = [
    {
      id: TestCaseFilterOption.ALL,
      name: 'All',
      selected: testCaseFilter === TestCaseFilterOption.ALL,
    },
    {
      id: TestCaseFilterOption.ACCEPTED,
      name: 'Accepted Only',
      selected: testCaseFilter === TestCaseFilterOption.ACCEPTED,
    },
    {
      id: TestCaseFilterOption.DISQUALIFIED,
      name: 'Disqualified Only',
      selected: testCaseFilter === TestCaseFilterOption.DISQUALIFIED,
    },
  ];

  const categoryCheckboxItems = data?.test_category.map((testCategory) => {
    return {
      id: testCategory.id,
      name: testCategory.name,
      selected: selectedTestCategoryIds.includes(testCategory.id),
    };
  });

  const onChangeCategorySelection = (updatedList) => {
    const newSelectedTestCategoryIds = updatedList.map((i) => i.id);
    setSelectedTestCategoryIds(newSelectedTestCategoryIds);
  };

  const onFilterDropDownChange = (item) => {
    setTestCaseFilter(item.id);
  };

  if (!sha) {
    return <div>SHA Missing</div>;
  }

  if (error) {
    return <div>{JSON.stringify(error)}</div>;
  }

  return (
    <Page className="AccuracyScorePage" heading="Accuracy Score Page" titleSegments={pageTitleSegments}>
      <div className="AccuracyScorePage-upperContainer">
        <div className="AccuracyScorePage-controls">
          <FeedFloTextInput
            label="Algorithm Sha:"
            text={sha}
            onChange={(t) => {
              setSha(t);
            }}
          />
          <ComboboxMulti
            label="Categories:"
            items={categoryCheckboxItems}
            onChange={onChangeCategorySelection}
            value={selectedTestCategoryIds}
          />
          <FeedFloDropDown list={testCaseFilterOptionList} label="Filter Cases:" onChange={onFilterDropDownChange} />
          <div className="AccuracyScorePage-controlsRow">
            <div className="AccuracyScorePage-controls--toggle">
              <span className="FeatureFlagManager-label">Calibrated:</span>
              <ToggleSwitch
                isActive={!uncalibrated}
                onToggle={(value) => {
                  setUncalibrated(!value);
                }}
              />
            </div>
            <div className="AccuracyScorePage-controls--toggle">
              <span className="FeatureFlagManager-label">Sort by:</span>
              <ToggleSwitch
                textLeft="Time"
                textRight="Error"
                isActive={sortBy === 'error-asc'}
                onToggle={(value) => {
                  // False = Time, True = error
                  // So flip it
                  if (value) {
                    setSort('error-asc');
                  } else {
                    setSort('time-asc');
                  }
                }}
              />
            </div>
          </div>
        </div>
        {(() => {
          const target_error_value = 0.05;
          const testcaseSuccessCount = Object.values(testCaseScores.metaData).reduce((count, curr) => {
            return count + (Math.abs(curr.score) < target_error_value ? 1 : 0);
          }, 0);
          const testcaseDoubleErrorMarginSuccessCount = Object.values(testCaseScores.metaData).reduce((count, curr) => {
            return count + (Math.abs(curr.score) < 2 * target_error_value ? 1 : 0);
          }, 0);
          const count = Object.keys(testCaseScores.metaData).length;
          const processedFeedFrames = Object.values(testCaseScores.metaData).reduce((count, curr) => {
            return count + curr.processedFeedFrames;
          }, 0);
          const totalFeedFrameCount = Object.values(testCaseScores.metaData).reduce((count, curr) => {
            return count + curr.totalFeedFrames;
          }, 0);
          const feedFloMassInGrams = Object.values(testCaseScores.metaData).reduce((count, curr) => {
            return count + curr.feedFloWeight;
          }, 0);
          const targetMassInGrams = Object.values(testCaseScores.metaData).reduce((count, curr) => {
            return count + curr.target_mass_in_grams;
          }, 0);
          const categorySuccess = (count - testcaseSuccessCount) / count < target_error_value;
          const categoryDoubleSuccess = (count - testcaseDoubleErrorMarginSuccessCount) / count < target_error_value;
          const listOfErrors = Object.values(testCaseScores?.metaData)?.map((d) => {
            return d?.score;
          });
          return (
            <Card
              loading={!testCaseScores.metaData}
              accentLocation="left"
              status={categorySuccess ? 'success' : categoryDoubleSuccess ? 'warning' : 'error'}
            >
              <h4>All Results</h4>
              <div className="categoryInfo">
                <div className="categoryStats">
                  <p>
                    On Target ±{target_error_value * 100}%:{' '}
                    <StatusBadge
                      status={categorySuccess ? 'success' : 'error'}
                      text={`${testcaseSuccessCount}/${count} | ${((testcaseSuccessCount / count) * 100).toFixed(0)}%`}
                    ></StatusBadge>
                  </p>
                  <p>
                    On Target ±{target_error_value * 200}%:{' '}
                    <StatusBadge
                      status={categoryDoubleSuccess ? 'success' : 'error'}
                      text={`${testcaseDoubleErrorMarginSuccessCount}/${count} | ${(
                        (testcaseDoubleErrorMarginSuccessCount / count) *
                        100
                      ).toFixed(0)}%`}
                    ></StatusBadge>
                  </p>
                  <p>
                    Frames Processed:{' '}
                    <StatusBadge
                      status={processedFeedFrames / totalFeedFrameCount > 0.99 ? 'success' : 'error'}
                      text={`${processedFeedFrames}/${totalFeedFrameCount}`}
                    ></StatusBadge>
                  </p>
                  <p>
                    Totals:{' '}
                    <StatusBadge
                      status={
                        Math.abs(feedFloMassInGrams / targetMassInGrams - 1) < target_error_value ? 'success' : 'error'
                      }
                      text={`${convertGramsToLargeUnits(isMetric, feedFloMassInGrams, 0)} / ${convertGramsToLargeUnits(
                        isMetric,
                        targetMassInGrams,
                        0,
                      )} ${weightLargeUnitLabel(isMetric)} | ${(
                        (feedFloMassInGrams / targetMassInGrams - 1) *
                        100
                      ).toFixed(0)}%`}
                    ></StatusBadge>
                  </p>
                </div>
                <div className="categoryChart">
                  <ErrorChart height={100} width={400} errors={listOfErrors} />
                </div>
              </div>
            </Card>
          );
        })()}
      </div>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <>
          <div className="CategoryHolder">
            {selectedTestCategoryIds.map((id) => (
              <TestCategory
                sha={sha}
                categoryId={id}
                key={id}
                testCaseFilter={testCaseFilter}
                setTestCaseMetaData={setTestCaseMetaData}
                uncalibrated={uncalibrated}
                sortBy={sortBy}
              ></TestCategory>
            ))}
          </div>
        </>
      )}
    </Page>
  );
}

AccuracyScorePage.propTypes = {
  titleSegments: PropTypes.arrayOf(PropTypes.string),
};

export default AccuracyScorePage;
