import dayjs from 'dayjs';
import { gql, useQuery } from '@apollo/client';
import { BinLevelPrediction } from '@norimaconsulting/bin-level-prediction';
import { useFeedFrameFilter, useAnalysisFilter } from '../../useFeedFrameFilter';
import { convertLargeUnitsToGrams, hoursToSeconds } from '../../unitConversion';
import { DeliveryStatus } from '../../enums';
import useFeature from '../useFeature';
import { convertToBinLevelTimeSeries } from '../../chartHelpers';
import { useAtomValue } from 'jotai';
import { algorithmVersionAtom } from '../../jotaiAtoms';

const defaultDeliverySizeInTons = 24;
const defaultShippingTimeInHours = 48;

// The number of months after which the 'large' sample rate set should be used

const BIN_INVENTORY_BIN_SET_LEVEL_GQL = gql`
  query BinInventory_BinSetLevelQuery($bin_set_id: uuid!, $valid_at: bigint!) {
    bin_set(where: { id: { _eq: $bin_set_id }, deleted_at: { _is_null: true } }) {
      id
      barn: farm {
        name
      }
      bin_set_level_resets: bin_set_levels(
        where: { valid_at: { _lte: $valid_at }, purpose: { _eq: reset }, deleted_at: { _is_null: true } }
        order_by: { valid_at: asc }
      ) {
        occurredAt: valid_at
        value: level_in_grams
      }
      earliest_bin_set_level: bin_set_levels(
        where: { valid_at: { _lte: $valid_at }, purpose: { _eq: reset }, deleted_at: { _is_null: true } }
        order_by: { valid_at: asc }
        limit: 1
      ) {
        valid_at
      }
      all_bin_set_levels: bin_set_levels(where: { deleted_at: { _is_null: true } }, order_by: { valid_at: asc }) {
        id
        valid_at
        level_in_grams
        source
        method
        purpose
        expected_deviation_in_grams
        comment
      }
    }
  }
`;

// TODO: We can make this bin_set_by_pk instead
const BIN_INVENTORY_BIN_SET_DATA_GQL = gql`
  query BinInventory_BinSetDataQuery(
    $bin_set_id: uuid!
    $start_at: bigint!
    $end_at: bigint!
    $known_bin_level_end_at: bigint!
    $feed_frame_where: feed_frame_bool_exp
    $feed_frame_analysis_where: feed_frame_analysis_bool_exp
    $feed_line_coefficients_where: feed_line_coefficient_bool_exp
    $algorithm_version: String!
  ) {
    bin_set(where: { id: { _eq: $bin_set_id }, deleted_at: { _is_null: true } }) {
      id
      farm {
        name
        barn_settings(where: { type: { _eq: "bin_inventory_management" }, version: { _eq: 0 } }) {
          settings
        }
      }
      bins(where: { deleted_at: { _is_null: true } }, order_by: { name: asc }) {
        name
        capacity_in_grams
        deliveries(
          where: {
            deleted_at: { _is_null: true }
            _or: [
              { delivered_at: { _gte: $start_at, _lte: $end_at }, status: { _eq: "delivered" } }
              { status: { _in: ["ordered", "recommended"] } }
            ]
          }
          order_by: { ordered_at: asc }
        ) {
          ordered_at
          delivered_at
          weight_in_grams
          status
        }
      }
      feed_lines(where: { deleted_at: { _is_null: true } }) {
        id
        device_assignments(where: { deleted_at: { _is_null: true }, status: { _eq: "active" } }) {
          device {
            transactions(
              where: { deleted_at: { _is_null: true } }
              order_by: { occured_at: desc_nulls_last }
              limit: 1
            ) {
              occured_at
            }
          }
        }
        feed_line_coefficients(where: $feed_line_coefficients_where, order_by: { ended_at: asc_nulls_last }) {
          coefficients(path: "linearCorrection")
          started_at
          ended_at
        }
        feed_frames(where: $feed_frame_where, order_by: { started_at: asc }) {
          started_at
          ended_at
          feed_frame_analyses(where: $feed_frame_analysis_where, order_by: { created_at: asc }, limit: 1) {
            mass_moved_in_grams
            latest_estimated_mass_moved_in_grams
            feed_frame_corrected_analyses(
              limit: 2
              where: {
                deleted_at: { _is_null: true }
                feed_line_coefficients: {
                  is_published: { _eq: true }
                  deleted_at: { _is_null: true }
                  algorithm_version: { _eq: $algorithm_version }
                }
              }
              order_by: [
                { feed_line_coefficients: { ended_at: desc_nulls_first } }
                { feed_line_coefficients: { started_at: desc } }
                { feed_line_coefficients: { created_at: desc } }
              ]
            ) {
              corrected_mass_moved_in_grams
            }
          }
        }
      }
      bin_set_levels(where: { deleted_at: { _is_null: true } }, order_by: { valid_at: asc }) {
        id
        valid_at
        level_in_grams
        source
        method
        use_for_inventory
        expected_deviation_in_grams
        comment
        purpose
      }

      # Grabs the most recently modified calibration
      bin_set_calibrations(order_by: { updated_at: desc }) {
        id
        created_at
        started_at
        ended_at
      }
    }
    known_bin_level: time_sample_known_bin_levels_for_bin_set(
      args: { interval_in_seconds: 3600, bin_set_id: $bin_set_id, start_at: $start_at, end_at: $known_bin_level_end_at }
    ) {
      valid_at: window_start
      level_in_grams: median_total_level
    }
  }
`;

const BIN_INVENTORY_BIN_SET_LEVEL_DELIVERY_GQL = gql`
  query BinInventory_BinChecks($bin_set_id: uuid!, $limit: Int!, $offset: Int!) {
    bin_set_level(
      where: { bin_set_id: { _eq: $bin_set_id }, deleted_at: { _is_null: true } }
      order_by: [{ valid_at: desc }, { id: asc }]
      limit: $limit
      offset: $offset
    ) {
      id
      bin_set_id
      valid_at
      level_in_grams
      source
      method
      purpose
      comment
      bin_set {
        id
        bins {
          id
          name
        }
      }
    }
    total_bin_set_level_rows: bin_set_level_aggregate(where: { bin_set_id: { _eq: $bin_set_id } }) {
      aggregate {
        count
      }
    }
    bin_set_with_bins: bin_set(where: { id: { _eq: $bin_set_id }, deleted_at: { _is_null: true } }) {
      id
      bins {
        id
        name
      }
    }
  }
`;

function findBinLevel(levels, targetTime, targetLevel = Number.MAX_SAFE_INTEGER) {
  let nearestLevel = levels[0];
  let minTimeDifference = Math.abs(targetTime - nearestLevel.rawOccurredAt);
  let minValueDifference = Math.abs(targetLevel - nearestLevel.value);

  for (const level of levels) {
    const timeDifference = Math.abs(targetTime - level.rawOccurredAt);
    const valueDifference = Math.abs(targetLevel - level.value);

    if (
      level.reason != 'Delivery' &&
      (timeDifference < minTimeDifference ||
        (timeDifference === minTimeDifference && valueDifference < minValueDifference))
    ) {
      minTimeDifference = timeDifference;
      nearestLevel = level;
    }
  }

  return {
    occurredAt: targetTime,
    value: nearestLevel.value,
  };
}

function findNearestLevels(targets = [], levels = []) {
  // return a list of points for use later. It should be the nearest "good" level for each coefficient
  // allowing us to snap to it
  const results = targets.map((target) => {
    return findBinLevel(levels, target.time, target.value);
  });

  return results;
}

export default function useBinInventory() {
  const { active: inventoryDebugMode } = useFeature('INVENTORY_DEBUG_MODE');
  const algorithmVersion = useAtomValue(algorithmVersionAtom);

  const fetchInventoryData = (binSetID = '', now = null, pageNum = 0, pageSize = 10) => {
    const {
      loading: binSetLevelLoading,
      error: binSetLevelError,
      data: binSetLevelData,
    } = useQuery(BIN_INVENTORY_BIN_SET_LEVEL_GQL, {
      variables: {
        bin_set_id: binSetID,
        valid_at: now.clone().unix(),
      },
      skip: !now,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache',
      errorPolicy: 'none',
    });
    const [binSet] = binSetLevelData?.bin_set || [];
    const [earliestBinSetLevel] = binSet?.earliest_bin_set_level || [];

    const {
      loading: binSetLevelDeliveryLoading,
      error: binSetLevelDeliveryError,
      data: binSetLevelDeliveryData,
    } = useQuery(BIN_INVENTORY_BIN_SET_LEVEL_DELIVERY_GQL, {
      variables: {
        bin_set_id: binSetID,
        limit: pageSize,
        offset: pageNum * pageSize,
      },
      fetchPolicy: 'no-cache',
      errorPolicy: 'none',
    });

    // grab all the bin set levels so we can treat them as overrides. Alternatively we could do more filtering of
    // binSetData.bin_set in InventoryCard
    const binSetLevelsForInventory =
      binSet?.bin_set_level_resets?.map((x) => ({ occurredAt: x.occurredAt, value: x.value })) || [];
    const earliestBinSetLevelDate = earliestBinSetLevel?.valid_at
      ? dayjs.tz(1000 * earliestBinSetLevel?.valid_at)
      : null;

    const hourDifference = now.diff(earliestBinSetLevelDate, 'hours');
    let startAt = now.clone().subtract(3, 'days').startOf('day').unix();

    // If closest bin level is <= 3 days from now, query 3 days of data, otherwise use the earliest bin set level date
    if (!hourDifference || hourDifference <= 72) {
      startAt = now.clone().subtract(3, 'days').startOf('day').unix();
    } else {
      // Will plot data since our earliest bin set level allowing for many to be marked as use_for_inventory
      startAt = earliestBinSetLevelDate.clone().utc().startOf('day').unix();
    }

    const feedFrameWhere = useFeedFrameFilter({
      started_at: {
        _gte: startAt,
        _lt: now.clone().unix(),
      },
      ended_at: { _is_null: false },
      deleted_at: { _is_null: true },
    });
    const feedFrameAnalysisWhere = useAnalysisFilter({ deleted_at: { _is_null: true } });
    const feedLineCoefficientsWhere = useAnalysisFilter({
      deleted_at: { _is_null: true },
      is_published: { _eq: true },
    });

    const {
      loading: binSetLoading,
      error: binSetError,
      data: binSetData,
    } = useQuery(BIN_INVENTORY_BIN_SET_DATA_GQL, {
      variables: {
        bin_set_id: binSetID,
        start_at: startAt,
        end_at: now.clone().unix(),
        known_bin_level_end_at: now.clone().add(30, 'days').unix(),
        feed_frame_where: feedFrameWhere,
        feed_frame_analysis_where: feedFrameAnalysisWhere,
        feed_line_coefficients_where: feedLineCoefficientsWhere,
        algorithm_version: algorithmVersion,
      },
      skip: !binSet || !feedLineCoefficientsWhere || !feedFrameAnalysisWhere || !startAt || !algorithmVersion,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache',
      errorPolicy: 'none',
    });

    return {
      chart: {
        loading: binSetLevelLoading || binSetLoading,
        error: binSetLevelError || binSetError,
        data: {
          binSetData,
          earliestBinSetLevel,
          binSetLevelsForInventory,
          all_bin_set_levels: binSet?.all_bin_set_levels,
        },
      },
      table: {
        loading: binSetLevelDeliveryLoading,
        error: binSetLevelDeliveryError,
        data: {
          binSetLevelDeliveryData,
        },
      },
    };
  };

  const getBinLevelPredictionData = async (binSet = {}, binLevel = null, now = null, binSetLevelOverrides = []) => {
    const names = [];
    const capacities = [];
    const feedEvents = [];
    const historicFeedEvents = [];
    const coefficientTimeSeries = [];
    const deliveries = [];
    const orders = [];

    // Get the bin names, capacities, deliveries, and pending orders in the bin set
    binSet?.bins?.forEach((bin) => {
      names.push(bin.name);
      capacities.push(bin.capacity_in_grams);

      bin?.deliveries?.forEach((delivery) => {
        if (delivery.status === DeliveryStatus.Delivered) {
          deliveries.push({
            occurredAt: delivery.delivered_at,
            value: delivery.weight_in_grams,
            status: delivery.status,
          });
        } else if (delivery.status === DeliveryStatus.Ordered) {
          orders.push({
            weight: delivery.weight_in_grams,
            orderedAt: delivery.ordered_at,
          });
        }
      });
    });

    // Get the list of feed events from the bin set
    binSet?.feed_lines?.forEach((line) => {
      // Track the latest timestamp for a recorded feed frame on this line.
      let latestOccurredAt = 0;

      // Okay so if there's more than 1 calibration the time between the first and second calibration is special and we want to use the
      // uncalibrated data as the "live" view, otherwise we want to show the first corrected analysis
      // TODO: This must be changed to work when add a feature to load less data.
      const uncalibratedRegionEndAt =
        line.feed_line_coefficients?.length > 1 ? line.feed_line_coefficients[1].started_at : dayjs().unix();

      line.feed_line_coefficients.forEach((coeff) => {
        coefficientTimeSeries.push({
          startedAt: new Date(coeff.started_at * 1000),
          rawOccurredAt: coeff.started_at,
          endedAt: coeff.ended_at ? new Date(coeff.ended_at * 1000) : null,
          endedAtUnix: coeff.ended_at,
          value: coeff.coefficients,
        });
      });
      line.feed_frames.forEach((frame) => {
        frame.feed_frame_analyses.forEach((analysis) => {
          const occurredAt = (frame.started_at + frame.ended_at) / 2;
          latestOccurredAt = Math.max(latestOccurredAt, occurredAt);
          feedEvents.push({
            feedline: line.id,
            occurredAt,
            value:
              analysis?.feed_frame_corrected_analyses?.at(0)?.corrected_mass_moved_in_grams ||
              analysis.mass_moved_in_grams, // grab the most recent FCA otherwise just grab uncalibrated
            // similar to latest_estimated_mass_moved_in_grams but this respects the current alg version
          });

          // Historic View
          historicFeedEvents.push({
            feedline: line.id,
            occurredAt,
            value:
              // if you're in the special first zone use the uncalibrated
              // otherwise use the earliest corrected mass as that's what they would have seen
              // if they looked at the chart at that time aka "live"
              occurredAt < uncalibratedRegionEndAt
                ? analysis?.mass_moved_in_grams
                : analysis?.feed_frame_corrected_analyses?.at(-1)?.corrected_mass_moved_in_grams,
          });
        });
      });
      line.device_assignments?.forEach((deviceAssignment) => {
        const latestDeviceTransaction = deviceAssignment?.device?.transactions?.[0]?.occured_at || 0;
        // For each device, if its latest transaction comes after its latest feed frame, insert a 0-value
        //  feed event at the time of the transaction. This tracks ongoing periods of 0 consumption.
        if (latestDeviceTransaction > latestOccurredAt) {
          feedEvents.push({
            feedline: line.id,
            occurredAt: latestDeviceTransaction,
            value: 0,
          });

          historicFeedEvents.push({
            feedline: line.id,
            occurredAt: latestDeviceTransaction,
            value: 0,
          });
        }
      });
    });

    // If there are no feed events, the predictor has nothing to run against so skip this bin set
    if (!feedEvents.length || binLevel === null) {
      return null;
    }

    const { settings } = binSet?.farm?.barn_settings?.[0] || {};
    const shippingTime = settings?.feed_order_shipping_time_in_seconds || hoursToSeconds(defaultShippingTimeInHours);

    const deliverySize =
      settings?.typical_feed_delivery_size_in_grams || convertLargeUnitsToGrams(false, defaultDeliverySizeInTons);

    // Ensure that the typical delivery size does not exceed the total capacity of the bin set
    const totalCapacity = capacities.reduce((total, curr) => total + curr, 0);
    const typicalOrderSizeInGrams = Math.min(deliverySize, totalCapacity);

    // Set up our overrides to work whether or not a value is passed or an Array
    let binOverrides = [
      {
        occurredAt: binLevel.valid_at,
        value: binLevel.level_in_grams,
      },
    ];
    if (Array.isArray(binSetLevelOverrides)) {
      binOverrides = binSetLevelOverrides;
    } else if (binSetLevelOverrides) {
      binOverrides = [binSetLevelOverrides];
    }

    const predictor = new BinLevelPrediction({
      binOverrides,
      feedEvents,
      deliveries,
      binCapacitiesInGrams: capacities,
      orderProcessingDelayInSeconds: shippingTime,
      typicalOrderSizeInGrams,
      debug: inventoryDebugMode,
    });

    await predictor.calculate({
      now: now.clone().unix(),
      minPoints: 2,
      minDurationInSeconds: 1,
    });

    // Historic Live Data START
    // for every coefficient change find the nearest "good" bin level to snap to
    const nearestBinLevels = findNearestLevels(
      coefficientTimeSeries
        .map((coef) => {
          const closestBinSetLevel = binSet?.bin_set_levels
            .filter((cur) => cur.purpose === 'calibrate')
            .reduce((min, cur) => {
              if (
                cur &&
                (!min?.valid_at ||
                  Math.abs(coef.rawOccurredAt - min.valid_at) > Math.abs(coef.rawOccurredAt - cur.valid_at))
              ) {
                return cur;
              } else {
                return min;
              }
            }, {});
          if (Math.abs(coef.rawOccurredAt - closestBinSetLevel.valid_at) > 60 * 60) {
            return null;
          }
          return {
            time: coef.rawOccurredAt,
            value: !isNaN(closestBinSetLevel?.level_in_grams) ? closestBinSetLevel?.level_in_grams : null,
          };
        })
        .filter((binSetLevel) => {
          return binSetLevel?.value != null;
        }),
      predictor?.debug?.binLevelTimeSeries,
    );

    // snap to the "end" of the lastest coefficient (ie the  bin check after used for calibration)
    // We know the historic line will snap down to the current best approximation where the next bin check is
    // Most of the time this is the most recent "Calibrate" bin check. But if we are mid-reprocessing it
    // will just be the next one so this check handles it now
    // when did the final coeff start? (Should be the same time as bin check)
    const lastCoefficientTime = coefficientTimeSeries.at(-1)?.rawOccurredAt;
    // now grab the _next_ bin check level
    const timeOfBinCheckAfterLastCoeff =
      binSet?.bin_set_levels?.find((x) => x.purpose === 'calibrate' && x.valid_at > lastCoefficientTime)?.valid_at ||
      lastCoefficientTime;

    const resetTimes = binSet?.bin_set_levels?.filter((x) => x.purpose === 'reset').map((x) => x.valid_at);
    resetTimes.push(Number.MAX_SAFE_INTEGER); // Added at the end to get the last snap needed

    const binChecksPriorToResets = resetTimes
      .map((resetTime) => {
        const output = binSet?.bin_set_levels
          .filter((cur) => cur.purpose === 'calibrate')
          .reduce((min, cur) => {
            if (cur && cur.valid_at < resetTime && (!min || resetTime - min.valid_at > resetTime - cur.valid_at)) {
              return cur;
            } else {
              return min;
            }
          }, null);
        return output;
      })
      .filter((x) => !!x);

    const nearestFinalCalibration = findNearestLevels(
      binChecksPriorToResets.map((x) => {
        return { time: x.valid_at, value: x?.level_in_grams };
      }),
      predictor?.debug?.binLevelTimeSeries,
    );

    const historicOverrides = [...binOverrides, ...nearestBinLevels, ...nearestFinalCalibration];
    historicOverrides.sort((a, b) => a.occurredAt - b.occurredAt); // sort to be safe

    const historicLiveViewSeries = convertToBinLevelTimeSeries({
      feedEvents: historicFeedEvents,
      deliveries,
      binOverrides: historicOverrides,
      debugMode: inventoryDebugMode,
      binSetCapacityInGrams: totalCapacity,
    });
    // Historic Live Data END

    return {
      name: binSet.farm.name,
      bins: names,
      orders,
      totalCapacity: predictor.totalCapacity,
      currentBinLevel: predictor.results.currentBinLevel,
      minimumSafe: predictor.results.minimumSafe,
      earliestDelivery: predictor.results.earliestDelivery,
      runOut: predictor.results.runOut,
      debug: predictor.debug,
      calibratedViewSeries: predictor.debug.binLevelTimeSeries.filter(
        (x) => x.rawOccurredAt < timeOfBinCheckAfterLastCoeff,
      ),
      historicLiveViewSeries,
      coefficientTimeSeries,
    };
  };

  return {
    fetchInventoryData,
    getBinLevelPredictionData,
  };
}
