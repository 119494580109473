import PropTypes from 'prop-types';
import LoadingSkeleton from '../../atoms/LoadingSkeleton';
import CalibrationChart from './CalibrationChart';
import Button from '../../atoms/Button';
import FeedFloToolTip from '../../atoms/FeedFloToolTip';
import { InfoIcon } from '../../atoms/Icons';
import Card from '../../atoms/Card';
import CoefficientChart from './CoefficientChart';
import FeedFloDateRangeInput from '../FeedFloDateRangeInput';
import dayjs from 'dayjs';
import { convertGramsToLargeUnits, weightLargeUnitLabel } from '../../utils/unitConversion';
import useUser from '../../utils/hooks/useUser';
import { DATE_TIME_FORMAT_SHORT } from '../../utils/dates';

function CalibrationCardView({
  chartLoading = false,
  data = {},
  chartSeries = [],
  binSetLevels,
  binSetCalibrations,
  deliveries = [],
  dataPaneChildren = <></>,
  selectedBinSetLevel = null,
  selectedBinSetCalibration = null,
  onBinSetLevelClicked = () => {},
  onBinSetCalibrationClicked = () => {},
  onClickNewBinSetCalibration,
  onClickNewBinSetLevel,
  feedlines,
  dateRange,
  setDateRange,
  error,
  displayCoefficientChart = false,
  binLevelResults = [],
  displayBinLevelTable = false,
}) {
  const { user } = useUser();
  const isMetric = user?.isMetric || false;

  const colourPalette = {
    calculated: '#26AF5F',
    projected: '#26AF5F', // same colour as calculated to show it continues
    historicDataColour: '#9be09b', // historic live data aka Calibrated (Live Data)
    deliveries: '#217BCE',
    truthData: '#65C9DA',
    binSetLevel: '#f2c94c', // purpose "Test" (not used for anything but visuals)
    binSetLevelCorrection: '#8B7229', // AKA Reset/Snap
    binSetLevelCalibration: '#4682B4', // purpose "Calibrate"
    calibrations: '#BA6057', // Visualing when a coefficient is used
  };

  const chartLines = [
    { label: 'Calibrated Inventory Level', colour: colourPalette.calculated },
    {
      label: 'Live Inventory Level',
      colour: colourPalette.historicDataColour,
    },
    {
      label: 'Calibration Bin Level',
      colour: colourPalette.binSetLevelCalibration,
    },
    {
      label: 'Reset Bin Level',
      colour: colourPalette.binSetLevelCorrection,
    },
    {
      label: 'Test Bin Level',
      colour: colourPalette.binSetLevel,
    },
    {
      label: 'Precision Calibration',
      colour: colourPalette.calibrations,
    },
  ];

  const renderChart = () => {
    if (chartLoading) {
      return (
        <>
          <div className="CalibrationCard-chartContainer">
            <LoadingSkeleton className="CalibrationCard-chartContainer--loader" />
          </div>
        </>
      );
    }

    return (
      <>
        <div className="CalibrationCard-centerContainer">
          <div className="CalibrationCard-chartContainer">
            <CalibrationChart
              startDate={dateRange?.from.unix()}
              endDate={dateRange?.to.unix()}
              chartSeries={chartSeries}
              binSetLevels={binSetLevels}
              binSetCalibrations={binSetCalibrations}
              deliveries={deliveries}
              onBinSetLevelClicked={onBinSetLevelClicked}
              onBinSetCalibrationClicked={onBinSetCalibrationClicked}
              selectedBinSetLevel={selectedBinSetLevel}
              selectedBinSetCalibration={selectedBinSetCalibration}
              loading={chartLoading}
            />
          </div>
        </div>
      </>
    );
  };

  const resetBinSetLevels = binSetLevels?.filter((bsl) => {
    return bsl.purpose === 'reset';
  });

  return (
    <div className="CalibrationCard">
      <div className="CalibrationCard-ChartAndHeaders">
        <div className="CalibrationCard-row">
          <div>
            <h3>{`Bins ${data?.bins || 'Loading'}`}</h3>

            <FeedFloToolTip description={`${feedlines.map((fl) => fl.name).join(', ')}`} position="bottom">
              <InfoIcon />
            </FeedFloToolTip>
          </div>
          <FeedFloDateRangeInput
            onChange={(e) => {
              setDateRange(e.dateRange);
            }}
            max={dayjs.tz()}
            from={dateRange?.from.toDate()}
            to={dateRange?.to.toDate()}
          />
        </div>
        {error && (
          <Card className="warningCard" status="error" accentLocation="top">
            <p>
              <h4>Oops! Something went wrong.</h4>
            </p>
            <p>
              <strong>
                Please try reloading the page, or check your internet connection. If the problem persists, contact
                support and include the error details below to help us resolve it faster.
              </strong>
            </p>
            <p> {JSON.stringify(error, Object.getOwnPropertyNames(error))}</p>
          </Card>
        )}
        {!error && (chartLoading || !!resetBinSetLevels?.length || !!binSetCalibrations?.length) && renderChart()}
        {!error && !chartLoading && !resetBinSetLevels?.length && !binSetCalibrations?.length && (
          <Card className="warningCard" status="warning" accentLocation="top" style={{ width: 'contain-content' }}>
            <h4>
              <strong>This bin set is not calibrated.</strong>
            </h4>
            <p>
              To proceed, either enter a <strong>Bin Check Reset</strong> and a <strong>Bin Check Calibration</strong>{' '}
              or complete an <strong>Exact Calibration</strong>.
            </p>
            <p>
              For assistance with the process, contact <a href="mailto:Support@feedflo.com">Support@feedflo.com</a> for
              step-by-step guidance.
            </p>
          </Card>
        )}
        {displayCoefficientChart && (
          <div className={'CalibrationCard-CoefficientChart'} style={{ height: '20rem' }}>
            <CoefficientChart
              startDate={dateRange?.from.unix()}
              endDate={dateRange?.to.unix()}
              feed_line_id={feedlines[0]?.id}
            />
          </div>
        )}
        {displayBinLevelTable && (
          <table className="CalibrationCard-BinLevelResults">
            <tr>
              <th>Barn</th>
              <th>Bin Set</th>
              <th>Time</th>
              <th>Purpose</th>
              <th>Target ({weightLargeUnitLabel(isMetric)})</th>
              <th>Live Value ({weightLargeUnitLabel(isMetric)})</th>
              <th>Live Error ({weightLargeUnitLabel(isMetric)})</th>
              <th>Recalculated Value ({weightLargeUnitLabel(isMetric)})</th>
              <th>Recalculated Error ({weightLargeUnitLabel(isMetric)})</th>
              <th>Total Feed Target ({weightLargeUnitLabel(isMetric)})</th>
              <th>Total Feed Live Error %</th>
            </tr>
            {binLevelResults.map((result) => {
              return (
                <tr key={result?.time}>
                  <th>{data?.barnName}</th>
                  <th>{data?.bins}</th>
                  <td>{dayjs.tz(result?.time * 1000).format(DATE_TIME_FORMAT_SHORT)}</td>
                  <th>{result.purpose}</th>
                  <td>{convertGramsToLargeUnits(isMetric, result?.target, 5)}</td>
                  <td>{convertGramsToLargeUnits(isMetric, result?.liveValue, 5)}</td>
                  <td>{convertGramsToLargeUnits(isMetric, result?.liveValue - result?.target, 5)}</td>
                  <td>{convertGramsToLargeUnits(isMetric, result?.recalculatedValue, 5)}</td>
                  <td>{convertGramsToLargeUnits(isMetric, result?.recalculatedValue - result?.target, 5)}</td>
                  <td>{convertGramsToLargeUnits(isMetric, result?.totalFeedMeasured, 5)}</td>
                  <td>{(100 * ((result?.liveValue - result?.target) / result?.totalFeedMeasured)).toFixed(1)}</td>
                </tr>
              );
            })}
          </table>
        )}
      </div>
      <div className="CalibrationCard-dataPaneContainer">
        {dataPaneChildren || (
          <>
            <h4>Legend</h4>
            <hr />
            <div className="CalibrationChart-legend">
              {chartLines.map(({ label, colour }, index) => {
                return (
                  <div className="legendItem" key={index}>
                    <div className="left">
                      <div className="circle" style={{ backgroundColor: colour }} />
                      {label}
                    </div>
                    <div className="icons"></div>
                  </div>
                );
              })}
            </div>
            <div className="CalibrationCard-dataPaneContainer-buttonHolder">
              <Button
                variant="pastel"
                color="success"
                disabled={chartLoading}
                content={'+ Bin Check'}
                onClick={onClickNewBinSetLevel}
              />
              <Button
                variant="pastel"
                color="success"
                disabled={chartLoading}
                content={'+ Exact Calibration'}
                onClick={onClickNewBinSetCalibration}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

CalibrationCardView.propTypes = {
  dialogRef: PropTypes.object,
  firstFieldRef: PropTypes.object,
  chartLoading: PropTypes.bool,
  data: PropTypes.object,
  chartSeries: PropTypes.array,
  tableData: PropTypes.object,
  pageNum: PropTypes.number,
  maxRows: PropTypes.number,
  onInsertBinSetLevel: PropTypes.func,
  onUpdateBinSetLevel: PropTypes.func,
  onDeleteBinSetLevel: PropTypes.func,
  onClickNext: PropTypes.func,
  onClickPrevious: PropTypes.func,
  feedlines: PropTypes.array,
  binSetLevels: PropTypes.array,
  binSetCalibrations: PropTypes.array,
  deliveries: PropTypes.array,
  dataPaneChildren: PropTypes.element,
  onBinSetLevelClicked: PropTypes.func,
  selectedBinSetLevel: PropTypes.string,
  selectedBinSetCalibration: PropTypes.string,
  onClickNewBinSetLevel: PropTypes.func,
  onBinSetCalibrationClicked: PropTypes.func,
  onClickNewBinSetCalibration: PropTypes.func,
  dateRange: PropTypes.object,
  setDateRange: PropTypes.func,
  error: PropTypes.object,
  displayCoefficientChart: PropTypes.bool,
  binLevelResults: PropTypes.array,
  displayBinLevelTable: PropTypes.bool,
};

export default CalibrationCardView;
