import { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { useQuery } from '@apollo/client';
import { useAtomValue } from 'jotai';
import { FaultCodes } from '@norimaconsulting/fault-codes';

import CollapsibleSection from '../../atoms/CollapsibleSection';
import Page from '../../atoms/Page';
import AnimalGroupHeader from '../../molecules/AnimalGroupHeader';
import FeedLineStatusCard from '../../organisms/FeedLineStatusCard';
import BinSetStatusCard from '../../organisms/BinSetStatusCard';
import DeviceStatusCard from '../../organisms/DeviceStatusCard';
import useFaultCodeFilter from '../../utils/useFaultCodeFilter';
import { TripStatus, TripStatusNameConverter } from '../FeedOrdersPage/enums';
import { BinSetCalibrationStatusEnum, FeedLineFullStatus, FeedLineRunningStatus } from './enum';
import { algorithmVersionAtom } from '../../utils/jotaiAtoms';

import useUser from '../../utils/hooks/useUser';
import { useFeedFrameFilter, useAnalysisFilter } from '../../utils/useFeedFrameFilter';

import { GetBarnSummaryDataQuery } from './queries';

import './BarnSummaryTab.scss';
import FeedLineStatusCardView from '../../organisms/FeedLineStatusCard/FeedLineStatusCardView';

const NUM_LOADING_FEED_LINES_AND_DEVICES = 4; // The number of feed line and device skeletons to display while loading

function BarnSummaryTab({
  loading: animalGroupHeaderLoading = false,
  titleSegments = [],
  className = '',
  barnId = '',
  organizationId = '',
}) {
  const BIN_PREDICTION_STALE_CUTOFF = useMemo(() => {
    return dayjs.tz().subtract(6, 'hour').unix();
  }, []); // Bin predictions older than this timestamp are stale and won't be shown.

  const { user } = useUser();
  const isMetric = user?.isMetric || false;
  const algorithmVersion = useAtomValue(algorithmVersionAtom);

  const pageTitleSegments = ['Summary', ...titleSegments];
  const [isBinSetSectionExpanded, setIsBinSetSectionExpanded] = useState(true);
  const [isAllSensorsSectionExpanded, setIsAllSensorsSectionExpanded] = useState(true);
  const feedFrameWhere = useFeedFrameFilter();
  const feedFrameAnalysisWhere = useAnalysisFilter();

  const faultFilter = { _and: [{ _or: useFaultCodeFilter() }, { ended_at: { _is_null: true } }] };

  const { loading: feedLineLoading, data: barnSummaryData } = useQuery(GetBarnSummaryDataQuery, {
    variables: {
      barnId,
      activeFaultWhere: faultFilter, // filters the active faults does not filter the barns
      deviceUnreachableFaultCode: FaultCodes.DEVICE_UNREACHABLE,
      emptyPipeFaultCode: FaultCodes.EMPTY_PIPE,
      augerRunningFaultCode: FaultCodes.AUGER_RUNNING,
      feedFrameWhere,
      feedFrameAnalysisWhere,
      binPredictionCutoff: BIN_PREDICTION_STALE_CUTOFF,
      sha: algorithmVersion,
    },
    skip: !barnId || !feedFrameAnalysisWhere,
    onError: (error) => console.error(`Error querying page data: ${error}`),
  });

  const onToggleBinSetSection = () => {
    setIsBinSetSectionExpanded(!isBinSetSectionExpanded);
  };

  const onToggleAllSensorsSection = () => {
    setIsAllSensorsSectionExpanded(!isAllSensorsSectionExpanded);
  };

  const renderBinSetsSection = () => {
    if (feedLineLoading || false) {
      return Array(Math.ceil(NUM_LOADING_FEED_LINES_AND_DEVICES / 2))
        .fill()
        .map((_, i) => (
          <div className="BarnSummaryTab-binSetList-BinSetStatusRow" key={`BinSetStatusRow${i}`}>
            <BinSetStatusCard key={i} loading />
            <div className="BarnSummaryTab-binSetList-FeedLineHolder">
              {Array(2)
                .fill()
                .map((_, j) => {
                  return <FeedLineStatusCard key={`FeedLineStatusCard${j}`} loading={true} />;
                })}
            </div>
          </div>
        ));
    }
    return barnSummaryData?.farm[0]?.bin_sets.map((binSet) => {
      //Priority (high to low) (Shipped -> scheduled -> Recomended -> Delivered)
      const priorityOrder = [TripStatus.Shipped, TripStatus.Scheduled, TripStatus.Recommended, TripStatus.Delivered];

      // The 0th index is the highest priority
      const orders = binSet?.bins
        ?.reduce((arr, bin) => {
          if (bin?.last_delivery) arr.push(...bin.last_delivery);
          if (bin?.scheduled_delivery) arr.push(...bin.scheduled_delivery);
          if (bin?.recommended_delivery) arr.push(...bin.recommended_delivery);
          if (bin?.shipped_delivery) arr.push(...bin.shipped_delivery);
          return arr;
        }, [])
        .sort((a, b) => {
          const priorityA = priorityOrder.indexOf(TripStatusNameConverter[a.status]);
          const priorityB = priorityOrder.indexOf(TripStatusNameConverter[b.status]);

          if (priorityA !== priorityB) {
            return priorityA - priorityB;
          }

          const timeA = a.delivered_at || a.ordered_at;
          const timeB = b.delivered_at || b.ordered_at;
          const now = dayjs().unix();

          // The time math depends on the status. Generally we want the one closest to now
          // so take the diff from now to give us the closest time to now
          // eg A happened 5s and B is scheduled in 10s. 5-10 = -5 meaning A is higher priority (earlier in the list)
          return Math.abs(now - timeA) - Math.abs(now - timeB);
        });

      const bins = binSet?.bins?.map((bin) => ({
        capacityInGrams: bin?.capacity_in_grams,
        name: bin?.name,
        lastDelivery: bin.last_delivery[0]?.delivered_at,
      }));
      const {
        bin_set_level_in_grams: binSetLevelInGrams,
        predicted_at: predictedAt,
        empty_at: emptyAt,
        earliest_delivery_at: earliestDeliveryAt,
        latest_order_by: latestOrderBy,
      } = binSet?.bin_set_level_predictions?.[0] || {};
      const feedLineNames = binSet?.feed_lines?.map((feedLine) => feedLine?.name);

      return (
        <div className="BarnSummaryTab-binSetList-BinSetStatusRow" key={`BinSetStatusRow${binSet.id}`}>
          <BinSetStatusCard
            key={binSet.id}
            binSetId={binSet.id}
            bins={bins}
            isMetric={isMetric}
            binSetLevelInGrams={binSetLevelInGrams}
            predictedAt={predictedAt}
            emptyAt={emptyAt}
            earliestDeliveryAt={earliestDeliveryAt}
            latestOrderBy={latestOrderBy}
            feedLineNames={feedLineNames}
            order={orders[0]}
          />
          <div className="BarnSummaryTab-binSetList-FeedLineHolder">
            {binSet.feed_lines.map((feedLine) => {
              let nofeedTimeInSeconds = 0;
              let mostRecentFeedFrame = null;
              let mostRecentTransaction = null;
              let currentDevice = null;
              let devices = [];
              let calibrationStatus;
              let task;
              let activeFaultCodes = [];
              let isFeedLineFull = FeedLineFullStatus.Unknown;
              let isFeedLineRunning = FeedLineRunningStatus.Unknown;
              let isCalculating = false;

              feedLine?.device_assignments?.forEach((deviceAssignment) => {
                currentDevice = deviceAssignment?.device;
                mostRecentFeedFrame = currentDevice?.feed_frames?.[0];
                mostRecentTransaction = currentDevice?.most_recent_transaction?.[0];
                activeFaultCodes = activeFaultCodes.concat(currentDevice?.active_alertable_fault_codes);
                nofeedTimeInSeconds = currentDevice?.active_alertable_fault_codes
                  ?.filter((fl) => fl.nofeed_seconds != null)
                  .reduce((max, fl) => Math.max(max, fl.nofeed_seconds), Number.MIN_SAFE_INTEGER);
                if (nofeedTimeInSeconds === Number.MIN_SAFE_INTEGER) {
                  nofeedTimeInSeconds = null;
                }
                isFeedLineFull = currentDevice?.active_alertable_fault_codes?.some(
                  (f) => f.code === FaultCodes.EMPTY_PIPE,
                )
                  ? FeedLineFullStatus.Empty
                  : FeedLineFullStatus.Full;

                isFeedLineRunning =
                  mostRecentFeedFrame?.duration === null
                    ? FeedLineRunningStatus.Running
                    : FeedLineRunningStatus.Stopped;

                const device = {
                  id: currentDevice?.id,
                  isOnline: currentDevice?.active_device_unreachable_fault?.length === 0,
                  serial: currentDevice?.serial,
                  feedLineName: feedLine?.name,
                  lastUploadInSeconds: mostRecentTransaction?.occured_at,
                };
                devices.push(device);

                calibrationStatus = BinSetCalibrationStatusEnum.Uncalibrated;

                isCalculating =
                  mostRecentFeedFrame?.duration !== null && !mostRecentFeedFrame?.feed_frame_analyses?.[0];

                if (feedLine?.feed_line_coefficients?.[0]) {
                  calibrationStatus = BinSetCalibrationStatusEnum.Calibrated;
                }

                task = feedLine?.bin_set.tasks?.[0]?.task;
                if (task) {
                  const due_at_date = dayjs(1000 * task.due_at);
                  const remind_at_date = dayjs(1000 * task.remind_at);
                  if (dayjs().isAfter(remind_at_date)) {
                    calibrationStatus = BinSetCalibrationStatusEnum.Due;
                  }
                  if (dayjs().isAfter(due_at_date)) {
                    calibrationStatus = BinSetCalibrationStatusEnum.PastDue;
                  }
                }
              });

              return (
                <FeedLineStatusCardView
                  key={feedLine.id}
                  feedLineId={feedLine.id}
                  nofeedTimeInSeconds={nofeedTimeInSeconds}
                  activeFaultCodes={activeFaultCodes}
                  feedLineName={feedLine.name}
                  feedLineLink={`/b/${barnId}/line/${feedLine.id}`}
                  isFeedLineFull={isFeedLineFull || ''}
                  isAugerRunning={isFeedLineRunning || ''}
                  isCalculating={isCalculating}
                  lastFeedingEventStartInSeconds={mostRecentFeedFrame?.started_at}
                  weightInGrams={mostRecentFeedFrame?.feed_frame_analyses?.[0]?.latest_estimated_mass_moved_in_grams}
                  durationInSeconds={mostRecentFeedFrame?.duration}
                  deviceList={devices}
                  calibrationStatus={calibrationStatus}
                  isMetric={isMetric}
                />
              );
            })}
          </div>
        </div>
      );
    });
  };

  const renderAllSensorsSection = () => {
    if (feedLineLoading) {
      return Array(NUM_LOADING_FEED_LINES_AND_DEVICES)
        .fill()
        .map((_, i) => {
          return <DeviceStatusCard key={i} loading={feedLineLoading} />;
        });
    }

    const deviceList = barnSummaryData?.farm[0]?.bin_sets.reduce((deviceList, binset) => {
      return deviceList.concat(
        binset?.feed_lines?.reduce((deviceList, feedLine) => {
          return deviceList.concat(
            feedLine?.device_assignments?.reduce((deviceList, deviceAssignment) => {
              deviceList.push(deviceAssignment?.device);
              return deviceList;
            }, []),
          );
        }, []),
      );
    }, []);

    if (!deviceList?.length) {
      return <span>No Active Sensors</span>;
    }

    return deviceList.map((device) => {
      const feed_line = barnSummaryData?.farm[0]?.bin_sets
        .reduce((feedLines, bin_set) => {
          return feedLines.concat(bin_set.feed_lines);
        }, [])
        .find((fl) => {
          return fl.device_assignments.some((d_a) => d_a.device.id === device.id);
        });

      const mostRecentTransaction = device?.most_recent_transaction?.[0];
      let nofeed_seconds = device?.active_alertable_fault_codes
        ?.filter((fl) => fl.nofeed_seconds != null)
        .reduce((max, fl) => Math.max(max, fl.nofeed_seconds), Number.MIN_SAFE_INTEGER);
      if (nofeed_seconds === Number.MIN_SAFE_INTEGER) {
        nofeed_seconds = null;
      }
      return (
        <DeviceStatusCard
          key={device?.id}
          ffId={device?.id}
          isOnline={device?.active_device_unreachable_fault?.length === 0}
          serial={device?.serial}
          feedLineName={feed_line.name}
          lastUploadInSeconds={mostRecentTransaction?.occured_at}
          feedLineLink={`/barns/${barnId}/line/${feed_line.id}`}
        />
      );
    });
  };

  return (
    <Page className={`BarnSummaryTab ${className}`} titleSegments={pageTitleSegments}>
      <AnimalGroupHeader
        loading={animalGroupHeaderLoading}
        className="BarnSummaryTab-animalGroupHeader"
        barnId={barnId}
        orgId={organizationId}
        allowGroupSelection={false}
      />
      {
        <CollapsibleSection
          className="BarnSummaryTab-section"
          titleClassName="BarnSummaryTab-sectionTitle"
          title="Bins"
          isExpanded={isBinSetSectionExpanded}
          onExpandCollapse={onToggleBinSetSection}
        >
          <div className="BarnSummaryTab-binSetList">{renderBinSetsSection()} </div>
        </CollapsibleSection>
      }
      <CollapsibleSection
        className="BarnSummaryTab-section"
        titleClassName="BarnSummaryTab-sectionTitle"
        title="All Sensors"
        isExpanded={isAllSensorsSectionExpanded}
        onExpandCollapse={onToggleAllSensorsSection}
      >
        <div className="BarnSummaryTab-deviceList">{renderAllSensorsSection()}</div>
      </CollapsibleSection>
    </Page>
  );
}

BarnSummaryTab.propTypes = {
  loading: PropTypes.bool,
  titleSegments: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
  barnId: PropTypes.string,
  organizationId: PropTypes.string,
};

export default BarnSummaryTab;
